import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import data from '../assets/images/success/success.json';
import Image from '../util/Image';
import Layout from '../layouts/main/Layout';
import '../style/main.scss';
import NoIndex from '../util/NoIndex/NoIndex';

const success = ({ ...props }) => {
  const intl = useIntl();
  const { successImageSrc, successImageAlt } = data.success;
  const { originalPath } = props.pathContext.intl;
  const { locale } = intl;
  const link = locale === 'pl' ? `/${locale}` : '';
  console.log(locale);
  return (
    <Layout originalPath={originalPath}>
      <NoIndex />
      <section className="success-page">
        <Image className="success-img" filename={successImageSrc} alt={successImageAlt} />
        <h1>
          <FormattedMessage id="success.header" />
        </h1>
        <p>
          <FormattedMessage id="success.description" />
        </p>
        <Link to={`${link}/`} className="btn btn-link">
          <FormattedMessage id="success.link" />
        </Link>
      </section>
    </Layout>
  );
};
export default success;
